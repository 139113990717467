/*
 * @Description:
 * @Date: 2024-09-30 19:25:20
 * @LastEditTime: 2024-10-14 15:34:38
 * @FilePath: \dog-vue3\src\api\user\hospital.ts
 */
import request from '@/utils/request';
import qs from 'qs';

interface Page {
  page: number;
  num: number;
}

export class queryModal {
  name: string = '';
}

export class createHospitalModal {
  phones: string[] = [];
  name: string = '';
  address: string = '';
  assignee: string = '';
}

export interface updateHospitalModal {
  name: string;
  address?: string;
  tel_add?: string[];
  tel_del?: string[];
}

// =================================================================================================
// ===========================================分割线:以下是api=======================================

/**
 * @description: 添加许可的医院
 * @param {createHospitalModal} data
 * @return {*}
 */
export const createHospital = (data: createHospitalModal) => {
  return request.post('/add_hospitals_access', null, {
    params: data,
    // paramsSerializer 序列化查询参数
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
};

/**
 * @description: 查询医院
 * @param {queryModal} data
 * @return {*}
 */
export const getList = (data: queryModal) => {
  return request.get('/get_hospitals', {
    params: data,
  });
};

export const deleteHospital = (name: string) => {
  return request.delete('/delete_hospital?name=' + name);
};

export const updateHospital = (params: updateHospitalModal) => {
  // 构建查询参数
  const queryParams: Record<string, string | string[]> = {
    name: params.name,
  };

  if (params.tel_add) {
    queryParams.tel_add = params.tel_add;
  } else if (params.tel_del) {
    queryParams.tel_del = params.tel_del;
  } else if (params.address) {
    queryParams.address = params.address;
  }

  return request.post(
    '/update_hospitalinfo',
    {},
    {
      params: queryParams,
      paramsSerializer: (params) => qs.stringify(params),
    }
  );

  // if (params.tel_add) {
  //   return request.post('/update_hospitalinfo?name=' + params.name + '&tel_add=' + params.tel_add);
  // } else if (params.tel_del) {
  //   return request.post('/update_hospitalinfo?name=' + params.name + '&tel_del=' + params.tel_del);
  // } else if (params.address) {
  //   return request.post('/update_hospitalinfo?name=' + params.name + '&address=' + params.address);
  // } else {
  //   return request.post('/update_hospitalinfo?name=' + params.name);
  // }
};
