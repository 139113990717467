import request from '@/utils/request';

//#region modal定义
export enum ERole {
  admin = 'ADMIN',
  lawer = 'LAWER',
  guest = 'GUEST',
}

interface Page {
  page: number;
  num: number;
}

export class passwdModal {
  tel: string = '';
  new_password: string = '';
  re_new_password: string = '';
}

export class createUserModal {
  tel: string = '';
  password: string = '';
  re_password: string = '';
  role: ERole | null = null;
}

export class queryUserModal implements Page {
  tel: string = '';
  hospital_name: string = '';
  page: number = 1;
  num: number = 30;
}
//#endregion

// =================================================================================================
// ===========================================分割线:以下是api=======================================
export const getUserList = (data: queryUserModal) => {
  return request.get('/get_all_user_by_list', {
    params: data,
  });
};

export const deleteUser = (id: number) => {
  return request.delete('/delete_user?id=' + id);
};

/**
 * @description: 更新密码
 * @param {any} data
 * @return {*}
 */
export const updateUserPassword = (data: passwdModal) => {
  // return request.post(
  //   '/update_passwd?tel=' + data.tel + '&new_password=' + data.new_password + '&re_new_password=' + data.re_new_password
  // );
  const params = new URLSearchParams({
    tel: data.tel,
    new_password: data.new_password,
    re_new_password: data.re_new_password,
  });
  return request.post(`/update_passwd?${params.toString()}`);
};

/**
 * @description: 新增用户
 * @param {createUserModal} data
 * @return {*}
 */
export const createUserByAdmin = (data: createUserModal) => {
  return request.post(
    '/create_user_from_admin?tel=' +
      data.tel +
      '&password=' +
      data.password +
      '&re_password=' +
      data.re_password +
      '&role=' +
      data.role
  );
};
