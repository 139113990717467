<template>
  <el-header class="mc-header">
    <el-row style="width: 100%; height: 100%">
      <el-col :span="12">
        <div class="header-title">
          <!-- <img src="../../../assets/images/logo-big.png" style="height: 84%" /> -->
          <span class="title">{{ title }}</span>
        </div>
      </el-col>

      <el-col
        :span="12"
        style="text-align: right; padding-right: 20px; display: flex; justify-content: flex-end; align-items: center"
      >
        <el-dropdown size="default" @command="handleCommand">
          <span class="el-dropdown-link drop-txt">
            您好，{{ userName }}
            <el-icon class="el-icon--right"><arrow-down /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item :command="ECommand.logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-col>
    </el-row>
  </el-header>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const title = process.env.VUE_APP_HEAD;

enum ECommand {
  logout = '退出登录',
}

const store = useStore();
const router = useRouter();

const userName = computed(() => {
  const role = store.state.user.role;
  const userName = store.state.user.userName;

  if (role && role.admin) {
    return 'admin';
  }

  if (role && role.superadmin) {
    return 'superadmin';
  }

  return userName || '未命名';
});

const handleCommand = (command: ECommand) => {
  if (command == ECommand.logout) {
    store.dispatch('user/logout');
    router.push('/login');
  }
};

onMounted(() => {});
</script>

<style scoped lang="less">
.mc-header {
  height: 64px;
  padding: 0;
  background-color: #1658a0;
  color: #fff;
}

.header-title {
  display: flex;
  align-items: center;
  height: 64px;
  padding-left: 20px;

  .title {
    padding-left: 10px;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 2px;
  }
}
.el-dropdown {
  color: inherit;
}
.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.drop-txt {
  display: flex;
  align-items: center;
}
</style>
