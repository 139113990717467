import { loginUser } from './login/loginAPI';
import * as blogAPI from './blog/blogAPI';
import * as userAPI from './user/user';
import * as userRoleAPI from './user/role';
import * as hospitalAPI from './user/hospital';

export default {
  loginUser,
  blogAPI,
  userAPI,
  userRoleAPI,
  hospitalAPI,
};
