<template>
  <el-footer class="mc-foot">{{ title }} {{ copyright }} {{ company }}</el-footer>
</template>

<script setup lang="ts">
const title = process.env.VUE_APP_HEAD;
const copyright = process.env.VUE_APP_COPYRIGHT;
const company = process.env.VUE_APP_US;
</script>

<style scoped>
.mc-foot {
  line-height: var(--el-footer-height);
  text-align: center;
  padding: 0;
  background-color: #f8f8f8;
  border-top: 1px solid #efeded;
}
</style>
