import { en } from 'element-plus/es/locale';

/*
 * @Description:
 * @Date: 2024-09-23 11:28:34
 * @LastEditTime: 2024-10-08 16:22:35
 * @FilePath: \dog-vue3\src\utils\type.ts
 */
export interface IRole {
  superadmin?: 'SUPERADMIN';
  admin?: 'ADMIN';
  lawer?: 'LAWER';
  hospital?: 'HOSPITAL';
  owner?: 'OWNER';
  guest?: 'GUEST';
}

export enum ERole {
  superadmin = 'SUPERADMIN',
  admin = 'ADMIN',
  lawer = 'LAWER',
  hospital = 'HOSPITAL',
  owner = 'OWNER',
  guest = 'GUEST',
}

export const roleMap: Record<string, string> = {
  guest: '普通用户',
  owner: '犬主',
  hospital: '宠物医院',
  lawer: '执法员',
  admin: '管理员',
  superadmin: '超管',
};

export interface blogCategory {
  notice: '政策通知';
  question: '常见问题';
}

export enum EBlogCategory {
  question = '常见问题',
  notice = '政策通知',
  default = '默认',
}
