import { App, DefineComponent } from 'vue';
import Table from './table/Table.vue';
import TableAlert from './table-alert/index.vue';
import dialog from './dialog/Dialog.vue';
import RadioGroup from './radio/RadioGroup.vue';
import Select from './select/Select.vue';
import SelectSearch from './select/SelectSearch.vue';
import DatePicker from './date-picker/DatePicker.vue';
import TimePicker from './time-picker/TimePicker.vue';
import Upload from './upload/Upload.vue';
import UploadImg from './upload/UploadImg.vue';
import WangEditor from './wangeditor/WangEditor.vue';

export default {
  install: (app: App): void => {
    app.component('MTable', Table);
    app.component('TAlert', TableAlert);
    app.component('MDialog', dialog);
    app.component('MRadioGroup', RadioGroup);
    app.component('MSelect', Select);
    app.component('MSelectSearch', SelectSearch);
    app.component('MDatePicker', DatePicker);
    app.component('MTimePicker', TimePicker);
    app.component('MUpload', Upload);
    app.component('MUploadImg', UploadImg);
    app.component('MRichText', WangEditor);
  },
};
