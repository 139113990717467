import { ref } from 'vue';
import { ERole } from '@/utils/type';

const routes = ref([
  {
    path: '/home',
    component: () => import('@/views/home.vue'),
    name: 'Home',
    meta: { title: '首页', noCache: true, icon: 'House', has: [ERole.admin, ERole.hospital, ERole.lawer] },
  },
  {
    path: '/audit-management',
    name: 'AuditManagement',
    meta: { title: '审核管理', noCache: true, icon: 'SetUp', has: [ERole.admin] },
    children: [
      {
        path: '/license-audit',
        component: () => import('@/views/dog-license/audit/Audit.vue'),
        name: 'LicenseAudit',
        meta: { title: '犬证审核', noCache: true, icon: 'List' },
      },
    ],
  },
  {
    path: '/dog-management',
    name: 'DogManagement',
    meta: { title: '犬只管理', noCache: true, icon: 'SetUp', has: [ERole.admin] },
    children: [
      {
        path: '/license-list',
        component: () => import('@/views/dog-license/management-list/List.vue'),
        name: 'LicenseList',
        meta: { title: '犬证管理', noCache: true, icon: 'List' },
      },
      {
        path: '/prohibited-dog',
        component: () => import('@/views/prohibited-dog/index.vue'),
        name: 'ProhibitedDog',
        meta: { title: '禁养犬只管理', noCache: true, icon: 'List' },
      },
    ],
  },
  {
    path: '/article-management',
    name: 'ArticleManagement',
    meta: { title: '文章管理', noCache: true, icon: 'Tickets', has: [ERole.admin] },
    children: [
      {
        path: '/article',
        component: () => import('@/views/article-management/article-list/List.vue'),
        name: 'ArticleList',
        meta: { title: '政策通知管理', noCache: true, icon: 'List' },
      },
      {
        path: '/questions',
        component: () => import('@/views/article-management/common-questions/index.vue'),
        name: 'Question',
        meta: { title: '常见问题管理', noCache: true, icon: 'List' },
      },
    ],
  },
  {
    path: '/law-enforcement-management',
    name: 'LawEnforcementManagement',
    meta: { title: '执法管理', noCache: true, icon: 'Magnet', has: [ERole.lawer] },
    children: [
      {
        path: '/alarm-info',
        component: () => import('@/views/law-management/alarm-information/index.vue'),
        name: 'AlarmInfo',
        meta: { title: '警报信息', noCache: true, icon: 'List' },
      },
      {
        path: '/case-info',
        component: () => import('@/views/law-management/case-information/index.vue'),
        name: 'CaseInfo',
        meta: { title: '案件信息', noCache: true, icon: 'List' },
      },
    ],
  },
  {
    path: '/confinement-management',
    name: 'ConfinementManagement',
    meta: { title: '收容管理', noCache: true, icon: 'OfficeBuilding', has: [ERole.lawer] },
    children: [
      {
        path: '/house',
        component: () => import('@/views/house-management/house/index.vue'),
        name: 'House',
        meta: { title: '收容管理', noCache: true, icon: 'List', has: [ERole.lawer] },
      },
    ],
  },
  {
    path: '/facility-management',
    name: 'FacilityManagement',
    meta: { title: '设备管理', noCache: true, icon: 'Odometer' },
    children: [
      {
        path: '/dog-brand',
        component: () => import('@/views/device-management/dog-brand-management/List.vue'),
        name: 'DogBrand',
        meta: { title: '犬牌管理', noCache: true, icon: 'List' },
      },
    ],
  },
  {
    path: '/system-management',
    name: 'SystemManagement',
    meta: { title: '系统管理', noCache: true, icon: 'Setting', has: [ERole.admin, ERole.superadmin] },
    children: [
      {
        path: '/users',
        component: () => import('@/views/system-management/user/User.vue'),
        name: 'User',
        meta: { title: '用户管理', noCache: true, icon: 'User', has: [ERole.admin] },
      },
      {
        path: '/hospital',
        component: () => import('@/views/system-management/hospital/HospitalList.vue'),
        name: 'Hospital',
        meta: { title: '医院管理', noCache: true, icon: 'SuitcaseLine', has: [ERole.admin] },
      },
      {
        path: '/roles',
        component: () => import('@/views/system-management/role/index.vue'),
        name: 'Role',
        meta: { title: '角色管理', noCache: true, icon: 'Avatar', has: [ERole.admin] },
      },
      {
        path: '/slideshow',
        component: () => import('@/views/system-management/mini-program/user-agreement/UserAgreement.vue'),
        name: 'Slideshow',
        meta: { title: '小程序用户协议', noCache: true, icon: 'List', has: [ERole.admin] },
      },
      {
        path: '/loginfo',
        component: () => import('@/views/system-management/log-info/LogInfo.vue'),
        name: 'Loginfo',
        meta: { title: '日志信息', noCache: true, icon: 'Coin', has: [ERole.admin] },
      },
    ],
  },
]);

export default routes;
